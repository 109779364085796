export const defaultExtraLocations = {
  california: "42.009503,-114.131211,32.534251,-124.5129241",
  colorado: "41.003372,-102.041522,36.992461,-109.060187",
  carbondale: "39.44214711,-107.10253761,39.29312697,-107.26486076",
  jefferson: "39.43340065,-105.69800191,39.27900455,-105.86615341",
  mccoy: "39.93254161,-106.70317471,39.89115707,-106.74860973",
  salida: "38.54462012,-105.9853155,38.52045875,-106.01132547",
  idaho: "49.000846,-111.043547,41.988182,-117.243027",
  montana: "49.0011,-104.039694,44.357936,-116.049108",
  oregon: "46.301396,-116.463407,41.9676592,-124.7060599",
  washington: "49.0121481,-116.916071,45.543541,-124.8616723",
  wyoming: "45.005815,-104.052245,40.994804,-111.054556"
}

export const defaultLocation = "47.80512104,-102.27156193,35.29887336,-116.30889193"
